import React from "react"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import {HomePic, Top, DownBtn, Direction, BtnGroup, BtnImg, Button, BtnTxt, Info, Intro} from "../styles/homeStyles"
import {DotContainer, Dot} from "../styles/projectsStyles";
import ScrollIntoView from 'react-scroll-into-view'
import Seo from "../components/seo"
import HomeImg from "../images/home.png"
import Down from "../images/icons8-expand-arrow-60.png"
import MarineLinkImg from "../images/MarineLink.png"
import NewDock from "../images/newDock.png"
import BargingImg  from "../images/barging.png"
import ContactForm from "../components/contact"
import Recent from "../components/recentBlogs"

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }

  render() {
    return (
      <Layout>
        <Seo 
          title="Docks, Barging & Pile Driving"
          description="We are your go-to guys for anything that needs built up, torn down and everything in between. We take pride in being versatile craftsman able to take on any new construction, repair or remodeling jobs for land or marine."
          keywords={["Jake On The Lakes", "Pile Driving Services", "New Dock Construction", "Barging Services", "Coeur d’Alene Lake", "Hauser Lake", "Newman Lake", "Priest Lake", "Pend Oreille Lake", "Cocolalla Lake", "Spokane River", "Expertise In The Northwest", process.env.DRUPAL_WEBFORM_ENDPOINT, process.env.GATSBY_PHONE_NUMBER]}
        />
        <Top>
          <HomePic style={{backgroundImage: `url(${HomeImg})`}}>EXPERT DOCK BUILDING</HomePic>
          <DownBtn>
            <ScrollIntoView style={{cursor: "pointer", margin: "0"}} selector="#next">
              <img style={{height: "60%", margin: "0"}} src={Down} alt="Down Arrow"/>
            </ScrollIntoView>
          </DownBtn>
        </Top>
        <Direction id="next">
          <BtnGroup>
            <Link to="/pile-driving-services/">
              <Button>
                <BtnImg style={{backgroundImage: `url(${MarineLinkImg})`}}/>
                <BtnTxt>PILE DRIVING SERVICES</BtnTxt>
              </Button>
            </Link>
            <Link to="/dock-construction-services/">
              <Button>
                <BtnImg style={{backgroundImage: `url(${NewDock})`}}/>
                <BtnTxt>NEW DOCK CONSTRUCTION</BtnTxt>
              </Button>
            </Link>
            <Link to="/barging-services/">
              <Button>
                <BtnImg style={{backgroundImage: `url(${BargingImg})`}}/>
                <BtnTxt>BARGING SERVICES</BtnTxt>
              </Button>
            </Link>
          </BtnGroup>
        </Direction>
        <Info>
          <div style={{fontSize: "22px"}}>EXPERTISE IN THE NORTHWEST</div>
          <DotContainer>
            <Dot style={{width: "8px"}}/>
            <Dot style={{width: "8px"}}/>
            <Dot style={{width: "8px"}}/>
          </DotContainer>
          <Intro><p>At Jake Terpstra's construction services, we have been proudly serving the Lake Coeur d'Alene and Hayden Lake areas for years, providing top-quality dock building, pile driving, and barging services. Our expert team has the experience and skills necessary to tackle a wide range of marine construction projects. We understand the unique requirements of working on these pristine lakes and are committed to delivering exceptional results that enhance your waterfront experience while preserving the natural beauty of these idyllic locations.</p>
<p>Dock building is one of our specialties, and we have built a solid reputation for creating stunning and functional docks on Lake Coeur d'Alene and Hayden Lake. From floating docks to stationary docks, our expert craftsmen use the highest quality materials and adhere to the latest industry standards to ensure your dock is built to last. We work closely with you to understand your needs and preferences, ensuring that your new dock is not only practical but also complements the surrounding landscape.</p>
<p>In addition to dock building, our team is well-versed in pile driving and barging services. Pile driving is an essential aspect of many marine construction projects, providing stability and support for structures like docks, boathouses, and seawalls. Our state-of-the-art equipment and experienced crew ensure that your piles are driven accurately and efficiently, minimizing the impact on the environment. Our barging services offer a convenient and cost-effective solution for transporting materials, equipment, and even personnel to and from your waterfront property. Whether you're undertaking a new construction project or simply need assistance with maintenance and repairs, our barging services streamline the process and help you get the job done with ease.</p></Intro>
        </Info>
        <ContactForm data={this.props.data} />
        <Recent/>
      </Layout>
    );
  };
};

export const query = graphql`
query {
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`


import styled from "styled-components";

const HomePic = styled.div`
    height: 94%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-size: 68px;
    letter-spacing: 16.5px;
    color: #662D91;
    background-size: cover;
    padding-bottom: 75px;
    background-position: center;
    
    @media (max-width: 1024px) {
        padding-bottom: 8%;
        padding-left: 6%;
        padding-right: 4%;
    }
    @media (max-width: 768px) {
        padding-bottom: 12%;
        font-size: 45px;
    }
    @media (max-width: 400px) {
        padding-bottom: 20%;
        font-size: 40px;
    }
`;

const Top = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1;
`;

const DownBtn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -18px;
`;

const Direction = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const Button = styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px;
    height: 100%;
    width: 25vw;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    @media (max-width: 768px) {
        width: 60vw;
        place-self: center;
        margin: 0;
    }
    @media (max-width: 600px) {
        width: 70vw;
    }
    @media (max-width: 450px) {
        width: 80vw;
    }
    
`;
const BtnTxt = styled.div`
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding-left: 1%;
    padding-right: 1%;
    border: solid #707070;
    border-top: none;
    border-width: thin;
    @media (max-width: 768px) {
        
    }
`;

const BtnImg = styled.div`
    background-size: cover;
    height: 70px;
    width: 100%;
    background-position-y: center;
`;

const BtnGroup = styled.div`
    display: flex;
    justify-content: center:
    align-items: center;
    height: 100%;
    margin-top: 25px;
    a:-webkit-any-link {
        color: #000;
        cursor: pointer;
        text-decoration: none;
        margin: 0;
        @media (max-width: 768px) {
            margin: 1.5%;
        }
    }
    @media (max-width: 768px) {
        height: 100%;
        flex-flow: row wrap;
        justify-content: center;
    }
`;

const Info = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 5%;
    padding-top: 4%;
    font-family: 'Raleway', sans-serif;
`;

const Intro = styled.div`
    width: 50%;
    @media (max-width: 768px) {
        width: 90%;
    }
`;

export {
    HomePic,
    Top,
    DownBtn,
    Direction,
    Button,
    BtnGroup,
    BtnImg,
    BtnTxt,
    Info,
    Intro
}
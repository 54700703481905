import React from 'react';
import {graphql, StaticQuery, Link} from "gatsby"
import {Container, Title, Featured, More, Arrow} from "../styles/recentStyles"
import {BlogCard, BlogExcerpt, BlogImg, Goto, BlogInfo, BlogTitle, ReadMore} from "../styles/blogsStyle"
import {getImage} from "../helpers";
import ArrowImg from "../images/arrow.png"

const RecentBlogs = () => {
    return (
        <Container>
            <Title>LATEST BLOGS</Title>
            <StaticQuery
                query={graphql`
                    query LatestQuery {
                        allNodeArticle(limit: 3, sort: {fields: created, order: DESC}) {
                            edges {
                                node {
                                    nid: drupal_internal__nid
                                    title
                                    body {
                                        processed
                                        summary
                                    }
                                    fields {
                                        slug
                                    }
                                    relationships {
                                        field_featured_image {
                                            relationships {
                                                field_media_image {
                                                    publicUrl
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <Featured>
                    {data.allNodeArticle.edges.map(({node}, i) => {
                        let photo = getImage(node);
                        return (
                            <BlogCard key={i}>
                              <BlogImg src={photo.url} alt={photo.alt}/>
                              <BlogInfo>
                                <BlogTitle><Link to={`${node.fields.slug}`}>{node.title}</Link></BlogTitle>
                                <BlogExcerpt>{node.body.summary ? node.body.summary : "It's hard to find long lasting, quality docks. Jake on the Lakes provides many services including dock building. We offer cedar float docks, poly float docks, Cedar-Poly float dock hybrid docks, and pier docks. Whether you need to build, install, or replace, Jake on the Lakes is here for your summer 2021 dock needs."}</BlogExcerpt>
                                <ReadMore to={`${node.fields.slug}`}>Read More	&gt;</ReadMore>
                              </BlogInfo>
                              <Goto to={`${node.fields.slug}`}>
                                <div>Read More</div>
                                <Arrow src={ArrowImg}/>
                              </Goto>
                            </BlogCard>
                          )
                    })}
                    </Featured>
                )}
                />

            <More to="/blogs/">Check out our blogs <Arrow src={ArrowImg}/></More>
        </Container>
    )
};
    
export default RecentBlogs
import styled from "styled-components";
import { Link } from 'gatsby'

const Container =styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: space-evenly;
    color: #707070;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    width: 66%;
    padding-top:40px;
    padding-bottom: 40px;
    margin-left: 17%;
    @media (max-width: 768px) {
        width: 80%;
        margin-left: 10%;
    }
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: 700;
    width: 100%;
`;

const Featured = styled.div`
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    text-align: left;
    @media (max-width: 768px) {
        flex-flow: row wrap;
    }
`;

const More = styled(Link)`
    color: #000;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
`;

const Arrow = styled.img`
    margin: 0;
    place-self: center;
    transform: rotateZ(-90deg);
    height: 20px;
    width: 20px;
    padding-top: 2px;
    padding-right: 2px;
`;

export {
    Container, 
    Title, 
    Featured, 
    More,
    Arrow
}